import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Modal,
  Button,
  Box,
  TextField,
  Breadcrumbs,
  Link,
} from "@mui/material";
import "./Service.css";
import FieldRenderer from "./FieldRenderer";
import axios from "axios"; // Import Axios
import { useTranslation } from "react-i18next";
function Service({ isModal, isOpen, onClose, serviceCard, onSave }) {
  const [isEditing, setEditing] = useState(false);
  const [isAdding, setAdding] = useState(false);
  const { t } = useTranslation();
  const [editedService, setEditedService] = useState({
    subgroup: decodeURIComponent(window.location.pathname.split("/")[2]),
    title: "",
    service_name: "",
    sub_system1: "",
    sub_system2: "",
    gov_app1: "",
    gov_app2: "",
    nongov_system1: "",
    nongov_system2: "",
    nongov_system3: "",
    nongov_app1: "",
    nongov_app2: "",
    nongov_app3: "",
    intro1: "",
    intro2: "",
    intro3: "",
  });
  const userRole = localStorage.getItem("role");

  useEffect(() => {
    if (!serviceCard) {
      setAdding(true);
    } else {
      setEditedService({ ...serviceCard });
      setAdding(false);
    }
  }, [serviceCard]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    if (isAdding) {
      onClose();
    } else {
      setEditing(false);
      setEditedService({ ...serviceCard });
    }
  };

  const handleSaveClick = () => {
    // Here, you can implement the logic to save the edited data to the server or perform other actions.
    // For this example, we'll update the editedData state.

    serviceCard = { ...editedService };
    if (isAdding) {
      const token = localStorage.getItem("token"); // Retrieve the authentication token from storage
      // Include the token in the request headers
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .post("/api/addService", editedService, { headers })
        .then((response) => {
          console.log("Service added successfully");
          // Reset the form or navigate to another page after successful submission
        })
        .catch((error) => {
          console.error("Error adding service:", error);
        });
    } else {
      onSave(editedService);
      setEditing(false);
    }
  };

  const handleInputChange = (field, value) => {
    setEditedService((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  if ((!isOpen || !editedService) && isModal) {
    return null;
  }

  const contentComponent = (
    <Card>
      <CardContent>
        {userRole === "admin" && isAdding ? (
          <div>
            <TextField
              fullWidth
              label={t("Subgroup")}
              value={editedService.subgroup}
              onChange={(e) => handleInputChange("subgroup", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Service Title")}
              value={editedService.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Service Name")}
              value={editedService.service_name}
              onChange={(e) =>
                handleInputChange("service_name", e.target.value)
              }
            />
          </div>
        ) : (
          <div>
            <Breadcrumbs dir="rtl" aria-label="breadcrumb">
              <Link underline="hover" color="inherit">
                {editedService.subgroup}
              </Link>
              <Link underline="hover" color="inherit">
                {editedService.title}
              </Link>
              <Typography color="text.primary">
                {editedService.service_name}
              </Typography>
            </Breadcrumbs>
          </div>
        )}
        {userRole === "admin" && (isEditing || isAdding) ? (
          <div>
            <TextField
              fullWidth
              label={t("Sub System 1")}
              value={editedService.sub_system1}
              onChange={(e) => handleInputChange("sub_system1", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Sub System 2")}
              value={editedService.sub_system2}
              onChange={(e) => handleInputChange("sub_system2", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Gov App 1")}
              value={editedService.gov_app1}
              onChange={(e) => handleInputChange("gov_app1", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Gov App 2")}
              value={editedService.gov_app2}
              onChange={(e) => handleInputChange("gov_app2", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Nongov System 1")}
              value={editedService.nongov_system1}
              onChange={(e) =>
                handleInputChange("nongov_system1", e.target.value)
              }
            />
            <TextField
              fullWidth
              label={t("Nongov System 2")}
              value={editedService.nongov_system2}
              onChange={(e) =>
                handleInputChange("nongov_system2", e.target.value)
              }
            />
            <TextField
              fullWidth
              label={t("Nongov System 3")}
              value={editedService.nongov_system3}
              onChange={(e) =>
                handleInputChange("nongov_system3", e.target.value)
              }
            />
            <TextField
              fullWidth
              label={t("Nongov App 1")}
              value={editedService.nongov_app1}
              onChange={(e) => handleInputChange("nongov_app1", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Nongov App 2")}
              value={editedService.nongov_app2}
              onChange={(e) => handleInputChange("nongov_app2", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Nongov App 3")}
              value={editedService.nongov_app3}
              onChange={(e) => handleInputChange("nongov_app3", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Intro 1")}
              value={editedService.intro1}
              onChange={(e) => handleInputChange("intro1", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Intro 2")}
              value={editedService.intro2}
              onChange={(e) => handleInputChange("intro2", e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Intro 3")}
              value={editedService.intro3}
              onChange={(e) => handleInputChange("intro3", e.target.value)}
            />
          </div>
        ) : (
          <div>
            {editedService.sub_system1 ? (
              <FieldRenderer
                label={t("Sub System 1")}
                value={editedService.sub_system1}
              />
            ) : null}
            {editedService.sub_system2 ? (
              <FieldRenderer
                label={t("Sub System 2")}
                value={editedService.sub_system2}
              />
            ) : null}
            {editedService.gov_app1 ? (
              <FieldRenderer
                label={t("Gov App 1")}
                value={editedService.gov_app1}
              />
            ) : null}
            {editedService.gov_app2 ? (
              <FieldRenderer
                label={t("Gov App 2")}
                value={editedService.gov_app2}
              />
            ) : null}
            {editedService.nongov_system1 ? (
              <FieldRenderer
                label={t("Nongov System 1")}
                value={editedService.nongov_system1}
              />
            ) : null}
            {editedService.nongov_system2 ? (
              <FieldRenderer
                label={t("Nongov System 2")}
                value={editedService.nongov_system2}
              />
            ) : null}
            {editedService.nongov_system3 ? (
              <FieldRenderer
                label={t("Nongov System 3")}
                value={editedService.nongov_system3}
              />
            ) : null}
            {editedService.nongov_app1 ? (
              <FieldRenderer
                label={t("Nongov App 1")}
                value={editedService.nongov_app1}
              />
            ) : null}
            {editedService.nongov_app2 ? (
              <FieldRenderer
                label={t("Nongov App 2")}
                value={editedService.nongov_app2}
              />
            ) : null}
            {editedService.nongov_app3 ? (
              <FieldRenderer
                label={t("Nongov App 3")}
                value={editedService.nongov_app3}
              />
            ) : null}
            {editedService.intro1 ? (
              <FieldRenderer
                label={t("Intro 1")}
                value={editedService.intro1}
              />
            ) : null}
            {editedService.intro2 ? (
              <FieldRenderer
                label={t("Intro 2")}
                value={editedService.intro2}
              />
            ) : null}
            {editedService.intro3 ? (
              <FieldRenderer
                label={t("Intro 3")}
                value={editedService.intro3}
              />
            ) : null}
          </div>
        )}
        {isModal || isModal === undefined ? (
          <Box display="flex" justifyContent="flex-end">
            {userRole === "admin" && (isEditing || isAdding) ? (
              <div>
                <Button variant="outlined" onClick={handleSaveClick}>
                  {t("Save")}
                </Button>
                <Button variant="outlined" onClick={handleCancelClick}>
                  {t("Cancel")}
                </Button>
              </div>
            ) : (
              <div>
                {userRole === "admin" ? (
                  <Button variant="outlined" onClick={handleEditClick}>
                    {t("Edit")}
                  </Button>
                ) : null}
                <Button variant="outlined" onClick={onClose}>
                  {t("Close")}
                </Button>
              </div>
            )}
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );

  return isModal || isModal === undefined ? (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {contentComponent}
    </Modal>
  ) : (
    contentComponent
  );
}

export default Service;
