import React, { useState } from "react";
import { useHistory } from "react-router-dom"; // Import useHistory from React Router
import "./UserRegistration.css";
import ErrorHandler from "./ErrorHandler";
import { useTranslation } from "react-i18next";
function UserRegistration() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory(); // Create a history object
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        setMessage(t("Registration successful! You can now log in."));
        // Redirect to the login page after a successful registration
        history.push("/login");
      } else {
        const data = await response.json();
        setMessage(data.message || t("Registration failed"));
      }
    } catch (error) {
      setError(error);
      setMessage(t("Registration failed"));
    }
  };

  return (
    <div className="registration-container">
      <h2>{t("User Registration")}</h2>
      <form className="registration-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">{t("First Name")}</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">{t("Last Name")}</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">{t("Email")}</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">{t("Password")}</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">{t("Confirm Password")}</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <button className="registration-button" type="submit">
          {t("Register")}
        </button>
      </form>
      {message && <p className="registration-message">{message}</p>}
      <ErrorHandler error={error} />
    </div>
  );
}

export default UserRegistration;
