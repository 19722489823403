import React, { useState, useEffect } from "react";
import {
  Modal,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
function EditServiceList({ data, open, onClose, onSave }) {
  const [editedData, setEditedData] = useState(data);
  const { t } = useTranslation();
  useEffect(() => {
    setEditedData({ ...data });
  }, [data]);

  const handleSave = () => {
    onSave(editedData);
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card>
        <CardContent>
          <Typography variant="h5">{t("Edit Service Title")}</Typography>
          <TextField
            fullWidth
            label={t("Service Title")}
            value={editedData.title}
            onChange={(e) =>
              setEditedData({ ...editedData, title: e.target.value })
            }
          />
          <TextField
            fullWidth
            label={t("Service Name")}
            value={editedData.service_name}
            onChange={(e) =>
              setEditedData({ ...editedData, service_name: e.target.value })
            }
          />
          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={handleSave}>
              {t("Save")}
            </Button>
            <Button variant="outlined" onClick={onClose}>
              {t("Close")}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}

export default EditServiceList;
