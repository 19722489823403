// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import faTranslations from "./translations/fa.json";

i18n.use(initReactI18next).init({
  resources: {
    fa: { translation: faTranslations },
  },
  lng: "fa", // Default language
  fallbackLng: "en", // Fallback language
});

export default i18n;
