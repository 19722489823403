import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

// import i18n (needs to be bundled ;))
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
