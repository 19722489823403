import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import "./NavigationBar.css"; // Import the CSS file for styling
import Service from "./Service";
import { useTranslation } from "react-i18next";

function NavigationBar() {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  const [isModalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <nav className="navbar">
        <div className="navbar-menu">
          {token ? <Link to="/dashboard">{t("Dashboard")}</Link> : null}
        </div>
        <div className="navbar-menu">
          {token && userRole === "admin" ? (
            <Button to="/destination" onClick={() => setModalOpen(true)}>
              {t("Create Service")}
            </Button>
          ) : null}
        </div>
        <ul className="navbar-menu">
          <li>
            {token ? <Link to="/userProfile">{t("User Profile")}</Link> : null}
          </li>
          <li>
            {token ? (
              <Link to="/logout">{t("Logout")}</Link>
            ) : (
              <Link to="/login">{t("Login")}</Link>
            )}
          </li>
        </ul>
      </nav>
      {userRole === "admin" ? (
        <Service isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
      ) : null}
    </>
  );
}

export default NavigationBar;
