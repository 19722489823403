import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Login.css"; // Import your CSS file for styling
import ErrorHandler from "./ErrorHandler";
import { useTranslation } from "react-i18next";
function Login() {
  const history = useHistory(); // Create a history object
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        const data = await response.json();
        const token = data.token;

        // Store the JWT token in local storage or a cookie
        localStorage.setItem("token", token);
        localStorage.setItem("role", data.role);

        setMessage(t("Login succeed"));

        // If authentication is successful, you can redirect to the dashboard
        history.push("/dashboard");
      } else {
        const data = await response.json();
        setMessage(data.message || t("Login failed"));
      }
    } catch (error) {
      setError(error);
      setMessage(t("Login failed"));
    }
  };

  return (
    <div className="login-container">
      <h2>{t("Login Page")}</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        {/* Form fields for email and password */}
        <div className="form-group">
          <label htmlFor="email">{t("Email")}</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">{t("Password")}</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="registration-link">
          {t("Don't have an account?")}{" "}
          <Link to="/register">{t("Register here")}</Link>
        </div>
        <button className="login-button" type="submit">
          {t("Login")}
        </button>
      </form>
      {message && <p className="login-message">{message}</p>}
      <ErrorHandler error={error} />
    </div>
  );
}

export default Login;
