import { useHistory } from "react-router-dom";

function Logout() {
  const history = useHistory(); // Create a history object
  // Clear the JWT token from local storage (or cookies if you prefer)
  localStorage.removeItem("token");

  // Redirect to the login page or perform other actions
  history.push("/login");
}

export default Logout;
