import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardContent,
  ListItemText,
  IconButton,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import EditSubgroupList from "./EditSubgroupList";
import ErrorHandler from "./ErrorHandler";
import axios from "axios"; // Import Axios

function SubgroupList() {
  const [subgroups, setSubgroups] = useState([]);
  const history = useHistory(); // Create a history object
  const userRole = localStorage.getItem("role");
  const [error, setError] = useState(null);

  const fetchData = useCallback(() => {
    try {
      const token = localStorage.getItem("token"); // Retrieve the authentication token from storage
      // Include the token in the request headers
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .get("/api/getSubgroups", { headers })
        .then((response) => setSubgroups(response?.data))
        .catch((error) => {
          setError(error);
          history.push("/logout");
        });
    } catch (error) {
      setError(error);
      history.push("/logout");
    }
  }, [history]);

  useEffect(() => {
    // Fetch data from your Node.js server
    fetchData();
  }, [fetchData]);

  const [subgroup, setSubgroup] = useState({ subGroupName: "" });

  const [isModalOpen, setModalOpen] = useState(false);

  const handleEditClick = (event, data) => {
    event.stopPropagation();
    setSubgroup(data);
    setModalOpen(true);
  };

  const handleCardClick = (data) => {
    history.push(`/serviceList/${data._id}`);
  };

  const handleSave = (editedData) => {
    const token = localStorage.getItem("token"); // Retrieve the authentication token from storage
    // Include the token in the request headers
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(
        `/api/editSubgroup/${editedData._id}`,
        {
          data: editedData,
        },
        { headers: headers }
      )
      .then((response) => {
        if (response) {
          // Fetch data from your Node.js server
          fetchData();
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <>
      <Container>
        <Grid container spacing={2}>
          {subgroups.map((element, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Card
                onClick={() => handleCardClick(element)}
                variant="outlined"
                style={{ width: "100%" }}
              >
                <CardContent>
                  <ListItemText primary={element._id} />
                </CardContent>
                {userRole === "admin" ? (
                  <IconButton
                    variant="outlined"
                    onClick={(event) =>
                      handleEditClick(event, {
                        ...element,
                        subGroupName: element._id,
                      })
                    }
                  >
                    <Edit />
                  </IconButton>
                ) : null}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {userRole === "admin" ? (
        <EditSubgroupList
          data={subgroup}
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
        />
      ) : null}
      <ErrorHandler error={error} />
    </>
  );
}

export default SubgroupList;
