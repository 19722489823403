import React from "react";
import Login from "./components/Login";
import UserRegistration from "./components/UserRegistration";
import NavigationBar from "./components/NavigationBar";
import UserProfile from "./components/UserProfile";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import ServiceList from "./components/ServiceList";
import Logout from "./components/Logout";
import "./App.css";
function App() {
  const token = localStorage.getItem("token");
  return (
    <Router forceRefresh={true}>
      <div>
        <NavigationBar />
        <Switch>
          <Route exact path="/login">
            {token ? <Redirect to="/dashboard" /> : <Login />}
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/userProfile">
            {token ? <UserProfile /> : <Login />}
          </Route>
          <Route path="/register">
            <UserRegistration />
          </Route>
          <Route exact path="/">
            {token ? <Redirect to="/dashboard" /> : <Login />}
          </Route>
          <Route exact path="/dashboard">
            {token ? <Dashboard /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/serviceList/:subgroup" component={ServiceList} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
