import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Service from "./Service";
import EditServiceTitle from "./EditServiceTitle";
import axios from "axios"; // Import Axios
import ErrorHandler from "./ErrorHandler";
import Search from "./Search.js";
import { useTranslation } from "react-i18next";
function ServiceList() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState();
  const [services, setServices] = useState([]);
  const [result, setResults] = useState();
  const [serviceCard, setServiceCard] = useState();
  const { subgroup } = useParams();
  const history = useHistory(); // Create a history object
  const userRole = localStorage.getItem("role");
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const fetchData = useCallback(() => {
    try {
      const token = localStorage.getItem("token"); // Retrieve the authentication token from storage

      // Include the token in the request headers
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .get("/api/getServiceList", { headers, params: subgroup })
        .then((response) => {
          if (response) {
            setServices(response.data);
            setResults(response.data);
          }
        })
        .catch((error) => {
          setError(error);
          history.push("/logout");
        });
    } catch (error) {
      setError(error);
      history.push("/logout");
    }
  }, [history, subgroup]);

  useEffect(() => {
    // Fetch data from your Node.js server
    fetchData();
  }, [fetchData]);

  const openModal = (service) => {
    setServiceCard(service);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleEditClick = (event, data) => {
    event.stopPropagation();
    setServiceCard(data);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (event, data) => {
    event.stopPropagation();
    setDataToDelete(data);
    // Open the delete confirmation dialog
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    const token = localStorage.getItem("token"); // Retrieve the authentication token from storage
    // Include the token in the request headers
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .delete(`/api/deleteService/${dataToDelete._id}`, { headers })
      .then((response) => {
        if (response) {
          // Fetch data from your Node.js server
          fetchData();
        }
      })
      .catch((error) => {
        console.error("Error deleting record:", error);
      });
    // Handle the delete confirmation logic here
    // For now, just close the dialog
    setDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    // Close the delete confirmation dialog
    setDeleteDialogOpen(false);
  };

  const handleSaveServiceTitle = (editedData) => {
    const token = localStorage.getItem("token"); // Retrieve the authentication token from storage
    // Include the token in the request headers
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(
        `/api/editServiceTitle/${editedData._id}`,
        {
          data: editedData,
        },
        { headers: headers }
      )
      .then((response) => {
        if (response) {
          // Fetch data from your Node.js server
          fetchData();
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleSaveService = (editedData) => {
    const token = localStorage.getItem("token"); // Retrieve the authentication token from storage
    // Include the token in the request headers
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(
        `/api/editService/${editedData._id}`,
        {
          data: editedData,
        },
        { headers: headers }
      )
      .then((response) => {
        if (response) {
          // Fetch data from your Node.js server
          fetchData();
        }
      })
      .catch((error) => {
        console.error("Error editing record:", error);
      });
  };

  return (
    <>
      <Container>
        <Typography align="right" variant="h4" gutterBottom>
          {subgroup}
        </Typography>
        <Search
          data={services}
          onSearch={(result) => {
            if (result?.length > 0) {
              setResults(result);
            } else {
              setResults(services);
            }
          }}
        />
        <Grid container spacing={2}>
          {result?.map((service, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Card
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => openModal(service)}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.primary"
                    gutterBottom
                  >
                    {service.title}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {service.service_name}
                  </Typography>
                </CardContent>
                {userRole === "admin" ? (
                  <>
                    <IconButton
                      variant="outlined"
                      onClick={(event) => handleEditClick(event, service)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      variant="outlined"
                      onClick={(event) => handleDeleteClick(event, service)}
                    >
                      <Delete />
                    </IconButton>
                  </>
                ) : null}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Service
        isOpen={isModalOpen}
        onClose={closeModal}
        serviceCard={serviceCard}
        onSave={handleSaveService}
      />
      {serviceCard && userRole === "admin" ? (
        <EditServiceTitle
          data={serviceCard}
          open={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          onSave={handleSaveServiceTitle}
        />
      ) : null}
      <ErrorHandler error={error} />
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>{t("Confirm Delete")}</DialogTitle>
        <DialogContent>{t("Are you sure you want to delete?")}</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ServiceList;
