import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  InputAdornment,
  IconButton,
  TextField,
  Container,
  Grid,
  Card,
} from "@mui/material";
import axios from "axios"; // Import Axios
import SearchIcon from "@mui/icons-material/Search";
import ErrorHandler from "./ErrorHandler";
import "./Search.css"; // Import the CSS file for styling
import Service from "./Service.js";
import { useTranslation } from "react-i18next";
const Search = ({ data, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [services, setServices] = useState();
  const [result, setResults] = useState();
  const [error, setError] = useState(null);
  const history = useHistory(); // Create a history object
  const { t } = useTranslation();
  const fetchData = useCallback(() => {
    try {
      const token = localStorage.getItem("token"); // Retrieve the authentication token from storage

      // Include the token in the request headers
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .get("/api/getServiceList", { headers })
        .then((response) => setServices(response?.data))
        .catch((error) => {
          setError(error);
          history.push("/logout");
        });
    } catch (error) {
      setError(error);
      history.push("/logout");
    }
  }, [history]);

  useEffect(() => {
    if (!data) {
      // Fetch data from your Node.js server
      fetchData();
    } else {
      setServices(data);
    }
  }, [fetchData, data]);

  const handleSearch = (e) => {
    let searchStr = e?.target?.value;
    if (searchStr === undefined) {
      searchStr = searchTerm;
    }
    if (searchStr !== "") {
      const result = services?.filter((obj) =>
        Object.values(obj).some((val) => {
          if (val) {
            return val?.includes(searchStr);
          }
          return 0;
        })
      );
      setResults(result);
      onSearch(result);
    } else {
      setResults();
      onSearch();
    }
  };

  return (
    <>
      <div className="search">
        <TextField
          variant="outlined"
          size="small"
          placeholder={t("Search...")}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e);
          }}
          InputProps={{
            style: { maxWidth: "100%" }, // Limit width to half of the screen
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          autoComplete="new-password"
          autoFocus={false}
        />
      </div>
      {!data && searchTerm !== "" ? (
        <Container>
          <Grid container spacing={2}>
            {result?.map((element, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Card variant="outlined" style={{ width: "100%" }}>
                  <Service isModal={false} serviceCard={element} />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      ) : null}
      <ErrorHandler error={error} />
    </>
  );
};

export default Search;
