function ErrorHandler({ error }) {
  if (!error) {
    return null;
  }

  // Handle different types of errors
  if (error.response) {
    // Server responded with an error status code
    console.error("Response data:", error.response.data);
    console.error("Response status:", error.response.status);
    console.error("Response headers:", error.response.headers);
  } else if (error.request) {
    // Request was made but no response was received
    console.error("Request:", error.request);
  } else {
    // Something went wrong in setting up the request
    console.error("Error:", error.message);
  }

  return null;
}

export default ErrorHandler;
