import React, { useState } from "react";
import SubgroupList from "./SubgroupList.js";
import Search from "./Search.js";
function Dashboard() {
  const [isSearching, setSearch] = useState(false);
  return (
    <>
      <Search
        onSearch={(result) => {
          if (result?.length > 0) {
            setSearch(true);
          } else {
            setSearch(false);
          }
        }}
      />
      {!isSearching ? <SubgroupList /> : null}
    </>
  );
}

export default Dashboard;
