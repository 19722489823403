import React from "react";

function FieldRenderer({ label, value }) {
  // Regular expression to match URLs within the text, while preserving "https://"
  const urlPattern = /(https?:\/\/[^\s]+)/g;

  // Split the field content into an array of text and link parts
  const contentParts = value.split(urlPattern);

  return (
    <div>
      {label} :{" "}
      {contentParts.map((part, index) => {
        if (urlPattern.test(part)) {
          // If the part is a URL, render it as a link
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        } else {
          // If the part is plain text, render it as text
          return <span key={index}>{part}</span>;
        }
      })}
    </div>
  );
}

export default FieldRenderer;
