import React, { useEffect, useState } from "react";
import axios from "axios";
import "./UserProfile.css"; // Import the CSS file
import ErrorHandler from "./ErrorHandler";
import { useTranslation } from "react-i18next";
function UserProfile() {
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const token = localStorage.getItem("token"); // Retrieve the authentication token from storage

    // Check if the token is present and valid
    if (token) {
      // Include the token in the request headers
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Fetch the user's profile data with the authorized request
      axios
        .get("/api/getProfile", { headers })
        .then((response) => {
          setUserProfile(response.data);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, []);

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    // Perform an API request to update the user's profile on the server
    // You can use the user data in the 'user' state to send updates
    // After successful update, set 'isEditing' to false
    try {
      const token = localStorage.getItem("token"); // Retrieve the authentication token from storage
      // Send a request to the server to change the password
      const response = await fetch("/api/updateProfile", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userProfile),
      });

      if (response.status === 200) {
        setMessage(t("Profile updated successfully"));
      } else {
        const data = await response.json();
        setMessage(data.message || t("Profile update failed"));
      }
    } catch (error) {
      setError(error);
      setMessage(t("Profile update failed"));
    }

    setIsEditing(false);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token"); // Retrieve the authentication token from storage
      // Send a request to the server to change the password
      const response = await fetch("/api/changePassword", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passwordData),
      });

      if (response.status === 200) {
        setMessage(t("Password changed successfully"));
      } else {
        const data = await response.json();
        setMessage(data.message || t("Password change failed"));
      }
    } catch (error) {
      setError(error);
      setMessage(t("Password change failed"));
    }
  };

  if (!userProfile) {
    return <div>{t("Loading...")}</div>;
  }

  return (
    <div className="user-profile">
      <div className="profile-header">
        <h2>{t("User Profile")}</h2>
      </div>
      <div className="profile-details">
        <div className="field">
          <label>{t("First Name")}</label>
          <span>
            {isEditing ? (
              <input
                type="text"
                name="firstName"
                value={userProfile.firstName}
                onChange={handleChange}
              />
            ) : (
              userProfile.firstName
            )}
          </span>
        </div>
        <div className="field">
          <label>{t("Last Name")}</label>
          <span>
            {isEditing ? (
              <input
                type="text"
                name="lastName"
                value={userProfile.lastName}
                onChange={handleChange}
              />
            ) : (
              userProfile.lastName
            )}
          </span>
        </div>
        <div className="field">
          <label>{t("Email")}</label>
          <span>{userProfile.email}</span>
        </div>
        {isEditing ? (
          <button className="save-profile-button" onClick={handleSaveClick}>
            {t("Save")}
          </button>
        ) : (
          <button className="edit-profile-button" onClick={handleEditClick}>
            {t("Edit")}
          </button>
        )}
      </div>
      <div className="change-password">
        <h3>{t("Change Password")}</h3>
        <form onSubmit={handlePasswordSubmit}>
          <div className="form-group">
            <label htmlFor="currentPassword">{t("Current Password")}</label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={passwordData.currentPassword}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">{t("New Password")}</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={passwordData.newPassword}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">{t("Confirm Password")}</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={passwordData.confirmPassword}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <button type="submit">{t("Change Password")}</button>
        </form>
        {message && <p>{message}</p>}
      </div>
      <ErrorHandler error={error} />
    </div>
  );
}

export default UserProfile;
